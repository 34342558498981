import React, { useState } from "react";
import { Navbar, Typography, Tooltip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { DialogDefault } from "./DialogDefault";
import { MapIcon, ShoppingBagIcon } from "@heroicons/react/24/outline";

export function ComplexNavbar() {
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  const handleLinkClick = (link, path) => {
    setActive(link);
    navigate(path);
  };

  return (
    <Navbar className="mx-auto max-w-screen-xl bg-richblack-25 p-2 lg:rounded-full lg:pl-40">
      <div className="relative mx-auto flex items-center justify-between text-blue-gray-900">
        {/* Middle part of Navbar */}
        <div className="flex items-center gap-6 mx-auto pl-20">
          {/* My Orders with Tooltip */}
          <Typography
            as="div"
            className={`cursor-pointer py-1.5 font-medium text-lg ${
              active === "Orders"
                ? "font-bold underline underline-offset-8"
                : ""
            } flex items-center`}
            onClick={() => handleLinkClick("Orders", "/profile/orders")}
          >
            <Tooltip content="My Orders" placement="bottom">
              <ShoppingBagIcon className="h-6 w-6 md:hidden" />
            </Tooltip>
            <span className="hidden md:inline">My Orders</span>
          </Typography>

          {/* Addresses with Tooltip */}
          <Typography
            as="div"
            className={`cursor-pointer py-1.5 font-medium text-lg ${
              active === "Addresses"
                ? "font-bold underline underline-offset-8"
                : ""
            } flex items-center`}
            onClick={() => handleLinkClick("Addresses", "/profile/addresses")}
          >
            <Tooltip content="Addresses" placement="bottom">
              <MapIcon className="h-6 w-6 md:hidden" />
            </Tooltip>
            <span className="hidden md:inline">Addresses</span>
          </Typography>
        </div>

        {/* Logout button with Tooltip */}
        <Tooltip content="Log Out" placement="bottom">
          <DialogDefault />
        </Tooltip>
      </div>
    </Navbar>
  );
}
