import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllUserData } from "../../services/operations/profileAPI";
import { getAllOrdersForUser } from "../../services/operations/order";
import { StepperWithContent } from "./StepperWithContent";

const OrdersDetails = () => {
  const { orderId } = useParams(); // Get orderId from URL parameters
  const { token } = useSelector((state) => state.auth);
  const [user, setUser] = useState(null);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDataAndOrderDetails = async () => {
      setLoading(true);
      try {
        // Fetch user data
        const userResponse = await getAllUserData(token);
        setUser(userResponse.userDetails);

        // Fetch all orders for the user
        const ordersResponse = await getAllOrdersForUser(
          userResponse.userDetails._id,
          token
        );

        // Find the specific order by orderId
        const foundOrder = ordersResponse.orders.find(
          (order) => order._id === orderId
        );
        setOrder(foundOrder);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching user data or order details", err);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchUserDataAndOrderDetails();
    }
  }, [token, orderId]); // Re-run effect if token or orderId changes
  console.log("user", user);
  console.log("order", order);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user || !order) {
    return <div>No user or order data found.</div>;
  }

  // Function to render the address
  const renderAddress = (address) => (
    <div className="border p-4 rounded-lg mt-4">
      <h3 className="text-lg font-semibold">Shipping Address</h3>
      <p>
        {address.firstName} {address.lastName}
      </p>
      <p>{address.address1}</p>
      {address.address2 && <p>{address.address2}</p>}
      <p>
        {address.city}, {address.zipCode}
      </p>
      <p>{address.country}</p>
      <p>Contact: {address.contactNumber}</p>
    </div>
  );

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-4">Order Details</h2>

      <div className="flex flex-wrap">
        {/* Left: Product Image and Details */}
        <div className="w-full md:w-1/2 p-4">
          <img
            src={order.orderItems[0].product.images[0].images_url[0]}
            alt={order.orderItems[0].product.name}
            className="w-full h-auto mb-4"
          />
          <h3 className="text-2xl font-bold">
            Demongears - {order.orderItems[0].product.name}
          </h3>
          <p>Color: {order.orderItems[0].product.color}</p>
          <p>Price: ₹{order.orderItems[0].product.discountedPrice}</p>
          <p>Total Price: ₹{order.totalAmount}</p>
        </div>

        {/* Right: Shipping Address and Order Status Timeline */}
        <div className="w-full md:w-1/2 p-4">
          {/* Address Section */}
          {order.shippingAddress &&
            renderAddress(
              user.addresses.find(
                (address) => address._id === order.shippingAddress
              )
            )}
        </div>
        {/* Order Status Timeline */}
        <StepperWithContent order={order} />
      </div>
    </div>
  );
};

export default OrdersDetails;
