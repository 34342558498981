import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Input,
  Button,
  Textarea,
  Typography,
  Select,
} from "@material-tailwind/react";
import {
  getAllCategoryName,
  getProductById,
  updateProduct,
} from "../../services/operations/Product";
import Upload from "../Profile/Upload";
import { CiCircleRemove } from "react-icons/ci";

const EditProducts = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [category, setCategory] = useState("");
  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: "",
    discountedPrice: "",
    stock: "",
    category: "",
    tags: [],
    features: {
      weight: "",
      dimensions: "",
      material: "",
    },
    colors: [{ color: "", images: [] }],
  });
  const [newCategory, setNewCategory] = useState("");
  const [categories, setCategories] = useState([]);

  // Fetch product details on mount
  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const result = await getProductById(productId, token);
      if (result && result.product) {
        setProduct(result.product);
      }
      setLoading(false);
    };
    fetchProduct();
  }, [productId, token]);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     const response = await getAllCategoryName();
  //     if (response?.categories) {
  //       setCategories(response.categories);
  //     }
  //   };
  //   fetchCategories();
  // }, []);

  // console.log("categories", categories);

  // Handle form submission for updating the product
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();

    // Append other product details
    formData.append("name", product.name);
    formData.append("description", product.description);
    formData.append("price", product.price);
    formData.append("discountedPrice", product.discountedPrice);
    formData.append("stock", product.stock);
    formData.append("features", JSON.stringify(product.features));
    formData.append("category", product.category.name || newCategory);

    // Append tags individually
    product.tags.forEach((tag, index) => {
      formData.append(`tags[${index}]`, tag);
    });

    // Append colors and images
    // Check if product.colors is defined and is an array
    if (Array.isArray(product.colors)) {
      product.colors.forEach((colorItem, index) => {
        // Check if colorItem.color is defined before appending
        if (colorItem.color) {
          formData.append(`colors[${index}][color]`, colorItem.color);
        }

        // Check if colorItem.images is defined and is an array before iterating
        if (Array.isArray(colorItem.images)) {
          colorItem.images.forEach((image) => {
            formData.append(`colors[${index}][images][]`, image); // Append image with array notation
          });
        }
      });
    }

    try {
      const result = await updateProduct(productId, formData, token);
      setLoading(false);
      if (result) {
        console.log("Product updated successfully:", result);
      }
    } catch (error) {
      console.error("Failed to update product", error);
      setLoading(false);
    }
  };

  // Handle changes to product fields
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setProduct((prevProduct) => ({
  //     ...prevProduct,
  //     [name]: value,
  //   }));
  // };

  // Handle changes for nested fields (features, colors)
  const handleFeaturesChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      productFeatures: {
        ...prevProduct.productFeatures, // Spread the previous productFeatures object
        [name]: value, // Dynamically update the specific feature (e.g., weight, dimensions)
      },
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleAddColor = () => {
    setColors([...colors, { color: "", images: [] }]);
  };

  const handleAddTag = (e) => {
    if (e.key === "Enter" && e.target.value) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        tags: [...(prevProduct.tags || []), e.target.value], // Ensure tags is an array
      }));
      e.target.value = "";
    }
  };

  const handleColorChange = (index, value) => {
    const updatedColors = product.colors.map((item, i) =>
      i === index ? { ...item, color: value } : item
    );
    setProduct((prevProduct) => ({
      ...prevProduct,
      colors: updatedColors,
    }));
  };

  const handleImageUpload = (index, uploadedFiles) => {
    const updatedColors = product.colors.map((item, i) =>
      i === index ? { ...item, images: uploadedFiles } : item
    );
    setProduct((prevProduct) => ({
      ...prevProduct,
      colors: updatedColors,
    }));
  };

  const handleRemoveTag = (index) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      tags: prevProduct.tags.filter((_, i) => i !== index), // Remove the tag by index
    }));
  };

  if (loading) return <div>Loading...</div>;
  return (
    <form
      onSubmit={onSubmit}
      enctype="multipart/form-data"
      className="space-y-4 pb-6"
      onKeyDown={handleKeyDown}
    >
      {/* Product Name */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Name
        </Typography>
        <Input
          name="name"
          placeholder="Product name"
          value={product.name} // Pre-fill with current product name
          onChange={(e) => setProduct({ ...product, name: e.target.value })}
          required
        />
      </div>

      {/* Description */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Description
        </Typography>
        <Textarea
          name="description"
          rows={5}
          placeholder="Write description"
          value={product.description} // Pre-fill with current description
          onChange={(e) =>
            setProduct({ ...product, description: e.target.value })
          }
          required
        />
      </div>

      {/* Original Price */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Original Price
        </Typography>
        <Input
          name="price"
          type="number"
          placeholder="e.g. 100.00"
          value={product.originalPrice} // Pre-fill with current price
          onChange={(e) =>
            setProduct({ ...product, originalPrice: e.target.value })
          }
          required
        />
      </div>

      {/* Discounted Price */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Discounted Price
        </Typography>
        <Input
          name="discountedPrice"
          type="number"
          placeholder="e.g. 80.00"
          value={product.discountedPrice} // Pre-fill with current discounted price
          onChange={(e) =>
            setProduct({ ...product, discountedPrice: e.target.value })
          }
          required
        />
      </div>

      {/* Stock */}
      <div>
        <Typography
          variant="small"
          color="blue-gray"
          className="mb-2 text-left font-medium"
        >
          Stock
        </Typography>
        <Input
          color="gray"
          size="lg"
          placeholder="Enter stock"
          value={product.stock}
          onChange={(e) => setProduct({ ...product, stock: e.target.value })}
          type="number"
          className="placeholder:opacity-100 focus:!border-t-gray-900"
          containerProps={{
            className: "!min-w-full",
          }}
          labelProps={{
            className: "hidden",
          }}
        />
      </div>

      {/* Category */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Category
        </Typography>
        <Select
          value={product?.category?.name}
          onChange={(e) => setProduct({ ...product, category: e.target.value })}
          label="Select Category"
          className="!w-full"
        >
          <option value="Clothing">Clothing</option>
          <option value="Fashion">Fashion</option>
          <option value="Watches">Watches</option>
          <option value="Gym Equipment">Gym Equipment</option>
        </Select>
        <Input
          color="gray"
          size="lg"
          placeholder="Type new category here"
          value={product?.category?.name}
          onChange={(e) => setProduct({ ...product, category: e.target.value })}
          className="mt-2"
        />
      </div>

      {/* Product Features */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Product Features
        </Typography>

        <Input
          name="weight"
          placeholder="Weight (e.g., 1.5 kg)"
          value={product?.productFeatures?.weight || ""} // Pre-fill with current weight
          onChange={handleFeaturesChange}
        />

        <Input
          name="dimensions"
          placeholder="Dimensions (e.g., 10x5x3 cm)"
          value={product?.productFeatures?.dimensions || ""} // Pre-fill with current dimensions
          onChange={handleFeaturesChange}
          className="mt-2"
        />

        <Input
          name="material"
          placeholder="Material (e.g., Cotton, Steel)"
          value={product?.productFeatures?.material || ""} // Pre-fill with current material
          onChange={handleFeaturesChange}
          className="mt-2"
        />
      </div>

      {/* Colors and Image Upload */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Product Colors and Images (Max 5 images per color)
        </Typography>
        {product.images?.map((imageItem, index) => (
          <div key={index} className="space-y-2">
            <div className="flex items-center gap-4">
              <Input
                value={imageItem.color || ""}
                onChange={(e) => handleColorChange(index, e.target.value)}
                placeholder={`Color ${index + 1}`}
                required
              />
              <Button color="red" onClick={() => handleRemoveColor(index)}>
                Remove Color
              </Button>
            </div>
            <Upload
              editData={true}
              name={`colorImages-${index}`}
              label={`Upload images for ${
                imageItem.color || `Color ${index + 1}`
              }`}
              maxFiles={5}
              onUpload={(uploadedImages) =>
                handleImageUpload(index, uploadedImages)
              }
            />
            {/* Display existing images */}
            <div className="mt-2 flex flex-wrap gap-4">
              {imageItem.images_url?.map((image, imgIndex) => (
                <div key={imgIndex} className="relative w-24 h-24">
                  {" "}
                  {/* Set width/height here */}
                  <img
                    src={image}
                    alt={`Product image for ${imageItem.color}`}
                    className="w-full h-full object-cover rounded-md"
                  />
                  <button
                    onClick={() => handleRemoveImage(index, imgIndex)}
                    className="absolute top-1 right-1 text-red-500 bg-white rounded-full p-1"
                  >
                    <CiCircleRemove className="h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
        <Button onClick={handleAddColor} className="mt-2">
          Add More Color
        </Button>
      </div>

      {/* Tags */}
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Tags
        </Typography>
        <Input onKeyDown={handleAddTag} placeholder="Press Enter to add tags" />
        <div className="flex flex-wrap gap-2 mt-2">
          {product?.tags?.map((tag, index) => (
            <div
              key={index}
              className="flex items-center gap-2 bg-gray-200 rounded-lg px-2 py-1"
            >
              <Typography>{tag}</Typography>
              <CiCircleRemove
                className="h-5 w-5 text-red-500 cursor-pointer"
                onClick={() => handleRemoveTag(index)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Submit Button */}
      <Button type="submit" disabled={loading}>
        {loading ? "Updating..." : "Update Product"}
      </Button>
    </form>
  );
};

export default EditProducts;
