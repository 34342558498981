import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { CiCircleRemove } from "react-icons/ci";

export default function Upload({
  name,
  label,
  register,
  setValue,
  errors,
  video = false,
  viewData = null,
  editData = null,
  maxFiles = 5,
  onUpload,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewSources, setPreviewSources] = useState(
    Array.isArray(viewData) ? viewData : Array.isArray(editData) ? editData : []
  );
  const inputRef = useRef(null);
  console.log(
    "data",
    "name",
    name,
    "label",
    label,
    "register",
    register,
    "setValue",
    setValue,
    "errors",
    errors,
    "video",
    video,
    "viewData",
    viewData,
    "editData",
    editData,
    "maxFiles",
    maxFiles,
    "onUpload",
    onUpload
  );

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.slice(0, maxFiles - selectedFiles.length); // Limit to remaining files
    if (newFiles.length > 0) {
      previewFiles(newFiles);
      setSelectedFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...newFiles];
        if (onUpload) onUpload(updatedFiles); // Call onUpload with updated files
        return updatedFiles;
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: !video
      ? { "image/*": [".jpeg", ".jpg", ".png"] }
      : { "video/*": [".mp4"] },
    onDrop,
    maxFiles,
    multiple: true,
    disabled: selectedFiles.length >= maxFiles,
  });

  const previewFiles = (files) => {
    const previewArr = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(previewArr).then((previewResults) => {
      setPreviewSources((prevSources) => [...prevSources, ...previewResults]);
    });
  };

  const removeImage = (index) => {
    const updatedSources = previewSources.filter((_, i) => i !== index);
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setPreviewSources(updatedSources);
    setSelectedFiles(updatedFiles);
    setValue(name, updatedFiles);
    if (onUpload) onUpload(updatedFiles); // Call onUpload with updated files
  };

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-sm text-richblack-900" htmlFor={name}>
        {label} {!viewData && <sup className="text-pink-200">*</sup>}
      </label>

      {/* Preview section */}
      <div className="flex flex-col space-y-4">
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
          {previewSources.map((src, index) => (
            <div key={index} className="relative group">
              <img
                src={src}
                alt={`Preview ${index + 1}`}
                className="h-full w-full rounded-md object-cover"
              />
              {!viewData && (
                <button
                  type="button"
                  onClick={() => removeImage(index)}
                  className="absolute top-2 right-2 text-white bg-red-500 rounded-full p-1 text-sm opacity-75 group-hover:opacity-100"
                >
                  <CiCircleRemove className="h-4 w-4" aria-hidden="true" />
                </button>
              )}
            </div>
          ))}
        </div>

        {/* "Add More" button: visible only when there are less than 5 images */}
        {selectedFiles.length < 5 && (
          <div
            className={`${
              isDragActive ? "bg-white" : "bg-white"
            } flex min-h-[100px] cursor-pointer items-center justify-center rounded-md border-2 border-dotted border-richblack-500`}
          >
            <div
              className="flex w-full flex-col items-center p-6"
              {...getRootProps()}
            >
              <input {...getInputProps()} ref={inputRef} />
              <div className="grid aspect-square w-14 place-items-center rounded-full bg-pure-greys-800">
                <FiUploadCloud className="text-2xl text-richred-300" />
              </div>
              <p className="mt-2 max-w-[200px] text-center text-sm text-richblack-900">
                Drag and drop {5 - selectedFiles.length} more{" "}
                {!video ? "image" : "video"}(s), or click to{" "}
                <span className="font-semibold text-richred-300">Browse</span>
              </p>
            </div>
          </div>
        )}

        {/* Error message when fewer than 3 images are selected */}
        {selectedFiles.length < 3 && (
          <span className="ml-2 text-xs tracking-wide text-pink-200">
            Please upload at least 3 images
          </span>
        )}
      </div>
    </div>
  );
}
