import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Input } from "@material-tailwind/react";

const ContactUsForm = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { firstName, lastName, email, password, confirmPassword } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginHandler = () => {
    navigate("/login");
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    // Dispatch signup action
  };

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <form
      className="flex flex-col gap-5 w-full mx-auto"
      onSubmit={handleOnSubmit}
    >
      <div className="flex flex-col gap-5 sm:flex-row">
        {/* First Name */}
        <Input
          label="First Name"
          type="text"
          name="firstName"
          value={firstName}
          onChange={handleOnChange}
          className="w-full"
        />

        {/* Last Name */}
        <Input
          label="Last Name"
          type="text"
          name="lastName"
          value={lastName}
          onChange={handleOnChange}
          className="w-full"
        />
      </div>

      {/* Email */}
      <Input
        label="Email"
        type="email"
        name="email"
        value={email}
        onChange={handleOnChange}
        className="w-full"
      />

      {/* Password and Confirm Password */}
      <div className="flex flex-col gap-5 sm:flex-row">
        {/* Password */}
        <Input
          label="Password"
          type={showPassword ? "text" : "password"}
          name="password"
          value={password}
          onChange={handleOnChange}
          className="w-full"
          icon={
            <div onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          }
        />

        {/* Confirm Password */}
        <Input
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleOnChange}
          className="w-full"
          icon={
            <div onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? (
                <AiOutlineEyeInvisible />
              ) : (
                <AiOutlineEye />
              )}
            </div>
          }
        />
      </div>

      {/* Submit Button */}
      <button
        disabled={loading}
        type="submit"
        className="w-full rounded-md bg-gradient px-4 py-2 text-white font-bold transition-transform duration-200 hover:scale-95 disabled:bg-gray-400"
      >
        Create Account
      </button>

      {/* Login Redirect */}
      <p
        onClick={loginHandler}
        className="text-center font-semibold text-blue-500 underline cursor-pointer mt-2"
      >
        Already have an account? Log In
      </p>
    </form>
  );
};

export default ContactUsForm;
