import React, { useEffect, useState } from "react";
import { getAllOrdersForAdmin } from "../../services/operations/order";
import { OrdersTable } from "../UI/OrdersTable";

const RecentOrders = () => {
  const [recentOrders, setRecentOrders] = useState(null);

  useEffect(() => {
    const fetchAllOrders = async () => {
      const response = await getAllOrdersForAdmin();

      setRecentOrders(response.orders);
    };
    fetchAllOrders();
  }, []);
  console.log("allproducts", recentOrders);

  return (
    <div>
      <OrdersTable recentOrders={recentOrders} />
    </div>
  );
};

export default RecentOrders;
