import React from 'react'

const TermsConditions = () => {
  return (
    <div>
      Hello
    </div>
  )
}

export default TermsConditions