import React, { useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import ConfirmationModal from "../common/ConfirmationModal";
import { deleteProduct } from "../../services/operations/Product";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = ["Product name", "Price", "Stock", "Total sell", ""];

export function MembersTable({ allProducts, setAllProducts }) {
  const [confirmationModal, setConfirmationModal] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleDeleleProduct = async (productId) => {
    const result = await deleteProduct({
      productId,
      token,
    });
    console.log("result", result);
    setAllProducts(result?.products);
    setConfirmationModal(null);
  };

  const handleEditProduct = (productId) => {
    navigate(`/profile/edit-product/${productId}`);
  };

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex flex-col md:flex-row items-center justify-between gap-4 md:gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              Products list
            </Typography>
            <Typography
              color="gray"
              className="mt-1 font-normal text-sm md:text-base"
            >
              See information about all Products By DemonGears
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-auto px-2 md:px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 md:p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70 text-xs md:text-sm"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allProducts && allProducts.length > 0 ? (
              allProducts.map((product, index) => {
                const isLast = index === allProducts.length - 1;
                const classes = isLast
                  ? "p-2 md:p-4"
                  : "p-2 md:p-4 border-b border-blue-gray-50";

                return (
                  <tr key={product._id}>
                    <td className={classes}>
                      <div className="flex flex-col md:flex-row items-start gap-2">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs md:text-sm"
                        >
                          {product.name}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70 text-xs md:text-sm truncate"
                        >
                          {product.description.slice(0, 30)}...
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs md:text-sm"
                      >
                        ₹ {product.discountedPrice}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs md:text-sm"
                      >
                        {product.stock}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs md:text-sm"
                      >
                        {product.buyers?.length || 0}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-2">
                        <Tooltip content="Edit Product">
                          <IconButton
                            variant="text"
                            onClick={() => handleEditProduct(product._id)}
                          >
                            <PencilIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content="Delete Product">
                          <IconButton variant="text" color="red">
                            <TrashIcon
                              className="h-4 w-4"
                              onClick={() =>
                                setConfirmationModal({
                                  text1: "Delete this Product ?",
                                  text2:
                                    "All the details related to this project will be deleted",
                                  btn1Text: "Delete",
                                  btn2Text: "Cancel",
                                  btn1Handler: () =>
                                    handleDeleleProduct(product._id),
                                  btn2Handler: () => setConfirmationModal(null),
                                })
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="p-2 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs md:text-sm"
                  >
                    No products available.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
      {/* Confirmation Modal */}
      {confirmationModal ? (
        <ConfirmationModal modalData={confirmationModal} />
      ) : null}
    </Card>
  );
}
