import React, { useEffect, useState } from "react";
import { MembersTable } from "../UI/MembersTable";
import { getAllProducts } from "../../services/operations/Product";

const AllProducts = () => {
  const [allproducts, setAllProducts] = useState(null);

  useEffect(() => {
    const fetchAllProducts = async () => {
      const response = await getAllProducts();

      setAllProducts(response?.products);
    };
    fetchAllProducts();
  }, []);
  console.log("allproducts", allproducts);

  return (
    <div className="p-2 md:p-4">
      <MembersTable allProducts={allproducts} setAllProducts={setAllProducts} />
    </div>
  );
};

export default AllProducts;
