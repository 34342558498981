import React, { useEffect, useState } from "react";
import { ProductCard } from "../components/Product/ProductCard";
import { getAllProducts } from "../services/operations/Product";
import { Spinner } from "@material-tailwind/react";

const Products = () => {
  const [productsData, setProductsData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const response = await getAllProducts();
      setProductsData(response?.products);
      setLoading(false);
    };
    fetchProduct();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner color="red" className="h-10 w-10" />
      </div>
    );
  }

  return (
    <div className="w-11/12 mx-auto h-screen mt-20 ">
      <div className="lg:w-10/12 md:w-full mx-auto">
        <h2 className="text-4xl font-bold text-gradient mb-8 text-center">
          Our Products
        </h2>
      </div>
      <div className="flex gap-7 ">
        {productsData &&
          productsData.map((data) => <ProductCard productData={data} />)}
      </div>
    </div>
  );
};

export default Products;
