import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { productEndpoints } from "../apis";

const {
  CREATE_PRODUCT,
  GET_ALL_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  PRODUCT_BY_CATEGORY,
  GET_ALL_CATEGORY_NAME,
  GET_ALL_REVIEWS,
  CREATE_RATING,
  GET_AVG_RATING,
  GET_ALL_RATING,
} = productEndpoints;

export async function getProductById(productId) {
  try {
    const response = await apiConnector("GET", GET_PRODUCT + `/${productId}`);
    console.log("GET_PRODUCT_BY_ID_API_RESPONSE......", response);
    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response?.data;
  } catch (error) {
    console.log("GET_PRODUCT_BY_ID_API_ERROR......", error);
    toast.error("Something went wrong");
  }
}

export async function getAllProducts() {
  try {
    const response = await apiConnector("GET", GET_ALL_PRODUCTS);
    console.log("GET_ALL_PRODUCTS_API_RESPONSE......", response);

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    console.log("GET_ALL_PRODUCTS_API_ERROR......", error);
    toast.error("Something went wrong");
  }
}

export const addProductDetails = async (productData, token) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", CREATE_PRODUCT, productData, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });

    console.log("CREATE product API RESPONSE............", response);

    toast.success("Product Details Added Successfully");
    result = response?.data;
  } catch (error) {
    console.log("CREATE PRODUCT API ERROR............", error);
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};

export const updateProduct = async (productData, token) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", UPDATE_PRODUCT, productData, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });

    console.log("UPDATE_PRODUCT API RESPONSE............", response);

    toast.success("Product Details uPDATED Successfully");
    result = response?.data?.data;
  } catch (error) {
    console.log("UPDATE_PRODUCT API ERROR............", error);
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};

// API call to get all category names
export async function getAllCategoryName() {
  try {
    console.log("111111: Starting API call to fetch category names");

    // Call the API using the connector function
    const response = await apiConnector("GET", GET_ALL_CATEGORY_NAME);

    console.log("222222: API call completed");

    // Optional: Uncomment this if your API returns a success flag to verify
    if (!response?.data?.success) {
      throw new Error(
        response?.data?.message || "Failed to fetch category names"
      );
    }

    // Return the category data
    return response.data;
  } catch (error) {
    console.error("GET_ALL_CATEGORY_NAME API ERROR......", error);

    // Notify the user about the error
    toast.error("Something went wrong while fetching categories");
  }
}

export const deleteProduct = async (data, token) => {
  console.log("data", data);
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("DELETE", DELETE_PRODUCT, data, {
      Authorization: `Bearer ${token}`,
    });
    console.log("DELETE_PRODUCT API RESPONSE............", response);
    if (!response?.data?.success) {
      throw new Error("Could Not DELETE_PRODUCT");
    }
    toast.success("Course Section Deleted");
    result = response?.data;
    console.log("result+++", result);
  } catch (error) {
    console.log("DELETE_PRODUCT API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export async function createRating(data, token) {
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("POST", CREATE_RATING, data, {
      Authorization: `Bearer ${token}`,
    });
    console.log("CREATE RATING API RESPONSE........", response);
    toast.success("Reivew submitted successfully");
    return response?.data;
  } catch (error) {
    console.log("CREATE RATING API RESPONSE ERROR........", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
}

export async function getAllRatings() {
  try {
    const response = await apiConnector("GET", GET_ALL_RATING);
    console.log("GET_ALL_RATING_api response......", response);

    // if (!response.data.success) {
    //   throw new Error(response.data.message);
    // }

    return response.data;
  } catch (error) {
    console.log("GET_ALL_RATING_api _ERROR ......", error);
    toast.error("Something went wrong");
  }
}
