import React from "react";
import { Stepper, Step, Typography } from "@material-tailwind/react";
import {
  CogIcon,
  UserIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";

export function StepperWithContent({ order }) {
  console.log("order========", order);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);

  // Placeholder icons for each step, you can change them as needed
  const icons = [UserIcon, CogIcon, BuildingLibraryIcon];

  // Define the fixed list of steps, regardless of status history
  const steps = ["Processing", "Shipped", "Delivered"];

  // Extract status history and map it by status
  const { statusHistory } = order;

  // Create a map for easier lookup of the history
  const statusMap = statusHistory.reduce((acc, status) => {
    acc[status.status] = status.timestamp;
    return acc;
  }, {});

  // Handle next and previous steps
  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  return (
    <div className="w-full px-24 py-4">
      <Stepper
        activeStep={activeStep}
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
      >
        {steps.map((step, index) => {
          const IconComponent = icons[index % icons.length]; // Cycle through icons
          const isActive = activeStep === index;
          const color = isActive ? "green" : "gray"; // Set color based on active state

          const timestamp = statusMap[step]
            ? new Date(statusMap[step]).toLocaleString()
            : "Not Available";

          return (
            <Step key={index} onClick={() => setActiveStep(index)}>
              <IconComponent className="h-5 w-5" />
              <div className="absolute -bottom-[4.5rem] w-max text-center">
                <Typography variant="h6" color={color}>
                  {step}
                </Typography>
                <Typography color={color} className="font-normal">
                  {timestamp}
                </Typography>
              </div>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
