import { setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { profileEndpoints } from "../apis";
import toast from "react-hot-toast";

const {
  CREATE_ADDRESS,
  DELETE_ADDRESS,
  GET_ALL_USER_DATA_API,
  UPDATE_DISPLAY_PICTURE_API,
  GET_ADMIN_DATA_API,
} = profileEndpoints;

const BASE_URL = "http://localhost:4000/api/v1/";

export const getAllUserData = async (token, dispatch) => {
  //check again
  let result = [];
  try {
    const response = await apiConnector("GET", GET_ALL_USER_DATA_API, null, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    dispatch(setUser(result?.userDetails));
  } catch (error) {
    console.log("GET_ALL_USER_DATA_API API ERROR............", error);
  }
  return result;
};

export function updateDisplayPicture(token, formData) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_DISPLAY_PICTURE_API,
        formData,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Display Picture Updated Successfully");
      dispatch(setUser(response.data.data));
    } catch (error) {
      console.log("UPDATE_DISPLAY_PICTURE_API API ERROR............", error);
      toast.error("Could Not Update Display Picture");
    }
    toast.dismiss(toastId);
  };
}

export async function getAdminData(token) {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("GET", GET_ADMIN_DATA_API, null, {
      Authorization: `Bearer ${token}`,
    });

    result = response?.data?.products;
  } catch (error) {
    console.log("GET_ADMIN_API ERROR", error);
    toast.error("Could not Get ADMIN Data");
  }
  toast.dismiss(toastId);
  return result;
}

export const addAddressDetails = async (data, token) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", CREATE_ADDRESS, data, {
      Authorization: `Bearer ${token}`,
    });

    toast.success("address Details Added Successfully");
    result = response?.data;
  } catch (error) {
    console.log("CREATE address API ERROR............", error);
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};

export async function deleteAddressDetails(token, addressId) {
  try {
    const response = await apiConnector(
      "DELETE",
      DELETE_ADDRESS + `/${addressId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    return response?.data;
  } catch (error) {
    console.log("DELETE_ADDRESS_API_ERROR......", error);
    toast.error("Something went wrong");
  }
}
