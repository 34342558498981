import React, { useState } from "react";
import {
  Rating,
  Spinner,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { createRating } from "../../services/operations/Product";
import { useSelector } from "react-redux";

const AddReviewModal = ({ productId }) => {
  const { token } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const handleOpen = () => setOpen(!open);
  const handleSubmit = async () => {
    if (rating !== 0) {
      const data = { rating, review, productId };
      console.log(data);
      const response = await createRating(data, token);
      console.log(response);
      setOpen(false);
    }
  };

  return (
    <>
      <Button onClick={handleOpen} variant="outlined" size="md" color="red">
        Write A Review
      </Button>{" "}
      <Dialog
        open={open}
        size="xs"
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <div className="flex items-center justify-between">
          <DialogHeader className="flex flex-col items-start">
            {" "}
            <Typography className="mb-1" variant="h4">
              Review And Rating
            </Typography>
          </DialogHeader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody>
          <Typography className="mb-5 -mt-7 " color="gray" variant="lead">
            Select rating and write a reivew for the product.
          </Typography>
          <div className="grid gap-4">
            <Typography className="-mb-1" color="blue-gray" variant="h6">
              Overall Rating
            </Typography>
            <Rating
              unratedColor="amber"
              ratedColor="amber"
              onChange={(value) => {
                setRating(value);
              }}
              value={rating}
            />
            <Textarea
              label="Review"
              onChange={(e) => {
                setReview(e.target.value);
              }}
            />
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="text" color="gray" onClick={handleOpen}>
            cancel
          </Button>
          <Button
            variant="gradient"
            className="bg-gradient"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AddReviewModal;
