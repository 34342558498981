import { setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { subscribeEndpoints } from "../apis";
import toast from "react-hot-toast";

const { CREATE_SUBSCRIBER_API } = subscribeEndpoints;

const BASE_URL = "http://localhost:4000/api/v1/";

export const addNewSubscriber = async (email) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", CREATE_SUBSCRIBER_API, {
      email,
    });
    toast.success("CREATE_SUBSCRIBER_API Successfully");
    result = response?.data;
  } catch (error) {
    console.log("CREATE_SUBSCRIBER_API_ERROR............", error);
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};
