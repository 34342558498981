// GET_ALL_ORDERS_OF_ADMIN_API;
import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { orderEndpoints } from "../apis";

const {
  GET_ALL_ORDERS_OF_ADMIN_API,
  GET_ALL_ORDERS_OF_USER_API,
  GET_ORDER_BY_ID,
  UPDATE_ORDER_API,
} = orderEndpoints;

export async function getAllOrdersForAdmin() {
  try {
    console.log("enters in api");
    const response = await apiConnector("GET", GET_ALL_ORDERS_OF_ADMIN_API);
    console.log("after response");
    console.log("GET_ALL_ORDERS_OF_ADMIN_API_RESPONSE......", response);

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    console.log("GET_ALL_ORDERS_OF_ADMIN_API_ERROR......", error);
    toast.error("Something went wrong");
  }
}

export async function getAllOrdersForUser(userId, token) {
  try {
    const response = await apiConnector(
      "GET",
      GET_ALL_ORDERS_OF_USER_API + `/${userId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log("GET_ALL_ORDERS_OF_USER_API response......", response);

    // if (!response.data.success) {
    //   throw new Error(response.data.message);
    // }

    return response.data;
  } catch (error) {
    console.log("GET_ALL_ORDERS_OF_USER_API error ......", error);
    toast.error("Something went wrong");
  }
}

export async function updateOrderStatusApi(token, orderId, orderStatus) {
  try {
    const response = await apiConnector(
      "PATCH",
      UPDATE_ORDER_API + `/${orderId}`,
      { orderStatus },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    console.log("UPDATE_ORDER_API response......", response);

    // if (!response.data.success) {
    //   throw new Error(response.data.message);
    // }

    return response.data;
  } catch (error) {
    console.log("UPDATE_ORDER_API error ......", error);
    toast.error("Something went wrong");
  }
}
