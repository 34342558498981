import React, { useState } from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  Input,
} from "@material-tailwind/react";
import { addAddressDetails } from "../../services/operations/profileAPI";
import { useDispatch, useSelector } from "react-redux";
// import { setUser } from "../../slices/profileSlice";

export function DrawerPlacement({ setUser }) {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openRight, setOpenRight] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    addressType: "",
    city: "",
    contactNumber: "",
    country: "",
    zipCode: "",
  });

  const openDrawerRight = () => setOpenRight(true);
  const closeDrawerRight = () => setOpenRight(false);

  const handleCancel = () => {
    closeDrawerRight();
    setFormData({
      // Reset form data on cancel
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      addressType: "",
      city: "",
      contactNumber: "",
      country: "",
      zipCode: "",
    });
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      const result = await addAddressDetails(formData, token);
      console.log("result", result);
      setUser(result?.addresses);
    } catch (error) {
      console.error("Failed to add address", error);
      alert("Failed to create address. Please try again.");
    } finally {
      setLoading(false);
      closeDrawerRight();
      setFormData({
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        addressType: "",
        city: "",
        contactNumber: "",
        country: "",
        zipCode: "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <React.Fragment>
      <div className="flex flex-wrap gap-4">
        <Button onClick={openDrawerRight}>Add Address</Button>
      </div>
      <Drawer
        placement="right"
        open={openRight}
        onClose={closeDrawerRight}
        className="p-4 w-[768px]" // Increased width of the drawer to double
      >
        <div className="mb-6 flex items-center justify-between">
          <Typography variant="h5" color="blue-gray">
            Add Address
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={closeDrawerRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>

        {/* Add Address Form */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreate();
          }}
        >
          <div className="flex flex-col gap-4 mb-6">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <Input
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <Input
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
            <div>
              <label
                htmlFor="address1"
                className="block text-sm font-medium text-gray-700"
              >
                Address 1
              </label>
              <Input
                id="address1"
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                placeholder="Address 1"
              />
            </div>
            <div>
              <label
                htmlFor="address2"
                className="block text-sm font-medium text-gray-700"
              >
                Address 2
              </label>
              <Input
                id="address2"
                name="address2"
                value={formData.address2}
                onChange={handleChange}
                placeholder="Address 2"
              />
            </div>
            <div>
              <label
                htmlFor="addressType"
                className="block text-sm font-medium text-gray-700"
              >
                Address Type
              </label>
              <Input
                id="addressType"
                name="addressType"
                value={formData.addressType}
                onChange={handleChange}
                placeholder="Address Type"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <Input
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
              />
            </div>
            <div>
              <label
                htmlFor="contactNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Contact Number
              </label>
              <Input
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                placeholder="Contact Number"
              />
            </div>
            <div>
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country
              </label>
              <Input
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Country"
              />
            </div>
            <div>
              <label
                htmlFor="zipCode"
                className="block text-sm font-medium text-gray-700"
              >
                Zip Code
              </label>
              <Input
                id="zipCode"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                placeholder="Zip Code"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <Button variant="outlined" type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit">Create</Button>
          </div>
        </form>
      </Drawer>
    </React.Fragment>
  );
}
