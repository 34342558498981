import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserData } from "../../services/operations/profileAPI";
import AddressCard from "../UI/AddressCard";
import { DrawerPlacement } from "../UI/DrawerPlacement";

const Address = () => {
  const { token } = useSelector((state) => state.auth);
  // const { user } = useSelector((state) => state.profile);
  const [user, setUser] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (token) {
          const response = await getAllUserData(token, dispatch);
          setUser(response.userDetails);
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    fetchUserData();
  }, [token]);
  console.log("user+++++", user);

  return (
    <div>
      {/* Address Cards */}
      <div className="flex flex-wrap justify-between gap-4">
        {user?.addresses &&
          user?.addresses.map((address, index) => (
            <AddressCard
              key={index}
              index={index}
              address={address}
              setUser={setUser}
            />
          ))}
      </div>

      {/* Add Address Button */}
      <div className="mt-6 flex justify-center">
        {user?.addresses?.length < 7 && <DrawerPlacement setUser={setUser} />}
      </div>
    </div>
  );
};

export default Address;
