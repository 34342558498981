import React from "react";
import {
  Input,
  IconButton,
  Typography,
  Button,
  Rating,
} from "@material-tailwind/react";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  addToCart,
  removeFromCart,
  removeProductFromCart,
} from "../../slices/cartSlice";
import { useNavigate } from "react-router-dom";

const CartProductCard = ({ item, index }) => {
  const [value, setValue] = React.useState(item.quantity);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calAvgRating = (productData) => {
    let rating = 0;
    if (
      productData?.ratingAndReviews &&
      productData.ratingAndReviews.length > 0
    ) {
      productData?.ratingAndReviews?.forEach((item) => {
        rating += item?.rating;
      });
      rating = rating / productData.ratingAndReviews.length;
    }
    console.log(Math.round(rating).toFixed(0));
    return rating.toFixed(1);
  };

  const decreaseQuanity = (cur) => {
    if (value !== 1) {
      setValue(value - 1);
      dispatch(removeFromCart(item.product._id));
    }
  };

  const increaseQuanity = () => {
    setValue(value + 1);
    const data = { productId: item.product._id };
    dispatch(addToCart(data));
  };

  const removeProduct = () => {
    dispatch(removeProductFromCart(item.product._id));
  };
  return (
    <div
      className={`flex w-full flex-wrap items-start justify-between gap-6
        border-b border-b-richblack-400 pb-6
      } ${index !== 0 && "mt-6"}`}
      key={index}
    >
      <div className="flex flex-1 flex-col gap-4 xl:flex-row">
        <img
          alt={item.product.name}
          src={item.product.images[0].images_url[0]}
          className="h-[148px] w-[220px] rounded-lg object-cover cursor-pointer"
          onClick={() => navigate(`/product/${item.product._id}`)}
        />
        <div className="flex flex-col space-y-1 w-[55%]">
          <p
            className="text-xl font-bold cursor-pointer"
            onClick={() => navigate(`/product/${item.product._id}`)}
          >
            {item.product.name}
          </p>
          <p
            className="line-clamp-2 text-richblack-500 cursor-pointer"
            onClick={() => navigate(`/product/${item.product._id}`)}
          >
            {item.product.description}
          </p>

          {/* rating section */}
          {
            <div className="flex items-center  gap-2">
              <span>{calAvgRating(item.product)}</span>
              <Rating
                unratedColor="amber"
                ratedColor="amber"
                readonly
                value={Math.round(calAvgRating(item.product))}
              />
            </div>
          }

          {/* price section */}
          <div className="pt-3 flex space-x-3">
            <span className="text-green-600 font-semibold text-xl">
              ₹{item.product.discountedPrice}
            </span>
            <span className="text-gray-400 line-through text-lg">
              ₹{item.product.originalPrice}
            </span>
          </div>
        </div>
      </div>

      <div className="w-32 ">
        <Typography
          variant="small"
          color="blue-gray"
          className="mb-1 font-medium"
        >
          Select Quantity
        </Typography>
        <div className="relative w-full">
          <Input
            disabled
            type="number"
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
            className="!border-t-blue-gray-200 placeholder:text-blue-gray-300 placeholder:opacity-100  focus:!border-t-gray-900 appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none max-w-32"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            containerProps={{
              className: "min-w-0",
            }}
          />
          <div className="absolute right-1 top-1 flex gap-0.5">
            <IconButton
              variant="text"
              size="sm"
              className="rounded"
              onClick={() => decreaseQuanity()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="h-4 w-4"
              >
                <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
              </svg>
            </IconButton>
            <IconButton
              variant="text"
              size="sm"
              className="rounded"
              onClick={() => increaseQuanity()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="h-4 w-4"
              >
                <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
              </svg>
            </IconButton>
          </div>
          <div>
            <Button
              variant="outlined"
              color="red"
              className="flex items-center justify-center gap-1 mt-3 w-32"
              onClick={() => removeProduct()}
            >
              <MdDeleteOutline className="text-[18px]" />
              Remove
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProductCard;
