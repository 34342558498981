import { useState } from "react";
import { Chart, registerables } from "chart.js";
import { Pie } from "react-chartjs-2";

Chart.register(...registerables);

const AdminChart = ({ allProducts }) => {
  console.log("++++++++++++++", allProducts);
  const [currChart, setCurrChart] = useState("buyers");

  const generateRandomColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)})`;
      colors.push(color);
    }
    return colors;
  };

  const chartDataBuyers = {
    labels: allProducts.map((product) => product.productName),
    datasets: [
      {
        data: allProducts.map((product) => product.totalBuyers),
        backgroundColor: generateRandomColors(allProducts?.length),
      },
    ],
  };

  const chartIncomeData = {
    labels: allProducts.map((product) => product.productName),
    datasets: [
      {
        data: allProducts.map((product) => product.totalAmountGenerated),
        backgroundColor: generateRandomColors(allProducts?.length),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className="flex flex-1 flex-col gap-y-4 rounded-md bg-richblack-800 p-4 md:p-6">
      <p className="text-lg font-bold text-richblack-5">Visualize</p>
      <div className="space-x-4 font-semibold">
        <button
          onClick={() => setCurrChart("buyers")}
          className={`rounded-sm p-1 px-3 transition-all duration-200 ${
            currChart === "buyers"
              ? "bg-richblack-700 text-yellow-50"
              : "text-yellow-400"
          }`}
        >
          Buyers
        </button>
        <button
          onClick={() => setCurrChart("income")}
          className={`rounded-sm p-1 px-3 transition-all duration-200 ${
            currChart === "income"
              ? "bg-richblack-700 text-yellow-50"
              : "text-yellow-400"
          }`}
        >
          Income
        </button>
      </div>
      <div className="relative mx-auto aspect-square h-[280px] w-[280px] md:h-[380px] md:w-[380px]">
        <Pie
          data={currChart === "buyers" ? chartDataBuyers : chartIncomeData}
          options={options}
        />
      </div>
    </div>
  );
};

export default AdminChart;
