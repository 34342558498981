import React, { useState } from "react";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = formData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Contact Us Form Submitted", formData);
  };

  return (
    <form
      className="flex flex-col gap-4 lg:gap-6 w-full mx-auto p-4 lg:p-6 border border-gray-300 rounded-lg shadow-md max-w-md"
      onSubmit={handleOnSubmit}
    >
      <h2 className="text-2xl lg:text-3xl font-semibold text-left text-gradient bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
        Contact Us
      </h2>

      <p className="text-xs lg:text-sm text-richblack-500">
        Your email address will not be published. Required fields are marked.
      </p>

      {/* Name Field */}
      <div className="flex flex-col gap-2">
        <label className="text-xs lg:text-sm font-semibold">Name</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm"
          type="text"
          name="name"
          value={name}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Email Field */}
      <div className="flex flex-col gap-2">
        <label className="text-xs lg:text-sm font-semibold">
          Email Address
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm"
          type="email"
          name="email"
          value={email}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Message Field */}
      <div className="flex flex-col gap-2">
        <label className="text-xs lg:text-sm font-semibold">Message</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm"
          name="message"
          value={message}
          onChange={handleOnChange}
          rows="4"
          required
        ></textarea>
      </div>

      <div className="flex justify-start">
        <button
          type="submit"
          className="w-1/3 lg:w-[20%] py-3 rounded-md bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-white font-bold text-center transition-transform duration-200 hover:scale-95 text-sm lg:text-base"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactUsForm;
